import * as Sentry from "@sentry/browser";
import StreamPlayer from "../AudioPlayer/StreamPlayer"
import { get, includes, isEmpty } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Button } from "react-toolbox/lib/button";
import Snackbar from "react-toolbox/lib/snackbar";
import Tooltip from "react-toolbox/lib/tooltip";
import BespokenDownloadIcon from "../../../assets/bespoken_download_icon_2.svg";
import BespokenGearIcon from "../../../assets/bespoken_gear_icon_2.svg";
import BespokenPowerIcon from "../../../assets/bespoken_power_icon.svg";
import BespokenSaveIcon from "../../../assets/bespoken_save_icon.svg";
import SpinnerImg from "../../../assets/spinner.svg";
import { setLoading } from "../../actions/loading";
import {
    addAssertion, addInteraction, addTest, cloneTest, deleteAssertion, deleteTest, getSource, removeInteraction, reorderTest, resultsInit, resultsPending, resultsUpdate, resultsUpdateAllPendingStatus, resultsUpdateInteractionAllStatus, saveSource, updateAssertionAction,
    updateAssertionOperator,
    updateAssertionValue, updateConfig, updateConfigNested, updateInteractionInput, updateInterimResults, updateMeta, updateMonitoringSchedule, updateName, updateOnlyFlag, updateSkipFlag, updateTestName,
    updateTestSuiteDescription,
    updateYaml,
    updateYamlObject, validateYamlSyntax
} from "../../actions/source";
import { TestResultStatus } from "../../constants";
import YamlHelper from "../../helpers/yaml-helper";
import BespokenUser from "../../models/bespoken-user";
import { Source } from "../../models/source";
import { State } from "../../reducers";
import { VirtualDevices } from "../../reducers/organization";
import BespokenApi, { BESPOKEN_API_WS_URL, BESPOKEN_API_MEDIA_URL, fetchBespokenApi } from "../../services/bespoken-api";
import { updateReportResultsProjectName } from "../../services/bespoken-reporting-api";
import * as Intercom from "../../services/intercom";
import WebsocketClient from "../../services/websocket-client";
import { Cell } from "../Grid/index";
import { IconLabelButton } from "../IconLabelButton/IconLabelButton";
import { BespokenModal } from "../Modal/BespokenModal";
import ReactDiv from "../ReactDiv";
import { SwitchLabel } from "../SwitchLabel/SwitchLabel";
import { PlatformIcon } from "../../components/PlatformIcons/PlatformIcons";
import { InplaceEditor, Line } from "../lunacy";
import { HasPrivilege } from "../lunacy/privileges/HasPrivileges";
import { MonitoringModal } from "./monitoring-modal";
import { SourceHeader } from "./sections/source-header";
import SourceSettings from "./sections/source-settings";
import TestEditor from "./sections/test-editor";
import TestList from "./sections/test-list";
import TestResults from "./sections/test-results";
import YamlEditor from "./sections/yaml-editor";
import { CurrentPageProps } from "../../reducers/context";
import { setCurrentPageProps } from "../../actions/context";
import { fetchInternalApi } from "../../services/internal-api";
import { wrapCallbackAsAsync } from "../../utils/ReactHelpers";
const globalWindow: any = typeof (window) !== "undefined" ? window : {};


const InlineEditInput = require("riek").RIEInput;

const TooltipDiv = Tooltip(ReactDiv);

const theme = require("../../themes/autosuggest.scss");
const inputTheme = require("../../themes/input.scss");
const tooltipTheme = require("../../themes/tooltip.scss");
const bespokenButton = require("../../themes/bespoken_button.scss");
const validationStyle = require("../validation/ValidationParentComponentStyle.scss");
const smallSwitchTheme = require("../../themes/small-switch-theme.scss");
const sourceStyle = require("./source-style.scss");

interface OwnProps {
    sourceId: string;
}

interface SourceProps {
    source: Source;
    interimResults: {
        [testIndex: number]: string[];
    }
    testResults: any;
    hasUnsavedChanges: boolean;
    finishLoading: boolean;
    loading: boolean;
    syntaxError: string;
    getSource: (userId: string, sourceId: string) => Promise<Source>;
    saveSource: (name: string, sourceId: string, yaml: any, config: any, meta: any, monitoringConfig: any) => Promise<any>;
    getTestResults: () => any;
    goTo: (path: string) => (dispatch: any) => void;
    updateConfig: (property: string, value: string) => void;
    updateConfigNested: (property: string, value: string) => void;
    updateMeta: (property: string, value: any) => Promise<any>;
    updateYaml: (value: any) => Promise<any>;
    updateName: (value: string) => Promise<any>;
    updateYamlObject: (value: any) => void;
    addTest: (name: string) => void;
    cloneTest: (index: number) => void;
    deleteTest: (index: number) => void;
    updateTestName: (testIndex: number, name: string) => void;
    updateSkipFlag: (testIndex: number, value: boolean) => void;
    updateOnlyFlag: (testIndex: number, value: boolean) => void;
    addInteraction: (testIndex: number, interactionIndex?: number) => void;
    removeInteraction: (testIndex: number, interactionIndex: number) => void;
    updateInteractionInput: (testIndex: number, interactionIndex: number, input: string) => void;
    addAssertion: (testIndex: number, interactionIndex: number) => void;
    deleteAssertion: (testIndex: number, interactionIndex: number, itemIndex: number) => void;
    updateAssertionAction: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionOperator: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionValue: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    resultsInit: (yamlObject: any, testIndex: number | number[], status: string) => Promise<any>;
    resultsPending: (yamlObject: any) => Promise<any>;
    resultsUpdateInteractionAllStatus: (testIndex: number, interactionIndex: number, status: string) => void;
    resultsUpdate: (testIndex: number, interactionIndex: number, conversationId: string, path: string, value: any) => void;
    validateYamlSyntax: (yaml: string) => void;
    resultsUpdateAllPendingStatus: (testIndex: number, status: string) => void;
    setLoading: (value: boolean) => void;
    updateMonitoringSchedule: (cron: string, emailsToNotify: string, numbersToNotify: string) => any;
    updateInterimResults: (testIndex: number, results: string[]) => void;
    reorderTest: (oldIndex: number, newIndex: number) => void;
    virtualDevices: VirtualDevices[];

    userId: string;

    updateTestSuiteDescription: (description: string) => void;
    organizationId: string;
    setCurrentPageProps: (value: CurrentPageProps) => any;

}

function mapStateToProps(state: State.All, ownProps: OwnProps) {
    return {
        source: state.sourceGit.currentSource,
        sourceId: ownProps.sourceId,
        interimResults: state.sourceGit.interimResults,
        testResults: state.sourceGit.testResults,
        hasUnsavedChanges: state.sourceGit.hasUnsavedChanges,
        finishLoading: state.sourceGit.finishLoading,
        loading: state.loading.loading,
        syntaxError: state.sourceGit.syntaxError,
        virtualDevices: state?.organization?.selectedOrganization?.virtualDevices,
        userId: state?.user?.currentUser?.id,
        organizationId: state?.organization?.selectedOrganization?.id,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        getSource: (userId: string, sourceId: string): Promise<any> => {
            return wrapCallbackAsAsync(handle => dispatch(getSource(userId, sourceId, handle)));
        },
        saveSource: (name: string, sourceId: string, yaml: any, config: any, meta: any, monitoringConfig: any): any => {
            return dispatch(saveSource(name, sourceId, yaml, config, meta, monitoringConfig))
        },
        reorderTest: (oldIndex: number, newIndex: number): any => {
            return dispatch(reorderTest(oldIndex, newIndex))
        },
        goTo: function (path: string) {
            dispatch(push(path));
        },
        updateConfig: (property: string, value: string): any => {
            return dispatch(updateConfig(property, value));
        },
        updateConfigNested: (property: string, value: string): any => {
            return dispatch(updateConfigNested(property, value));
        },
        updateMeta: (property: string, value: string): any => {
            return dispatch(updateMeta(property, value));
        },
        updateYaml: (value: any): any => {
            return dispatch(updateYaml(value));
        },
        updateName: (value: string): any => {
            return dispatch(updateName(value));
        },
        updateYamlObject: (value: any): any => {
            return dispatch(updateYamlObject(value));
        },
        addTest: function (name: string) {
            dispatch(addTest(name))
        },
        cloneTest: function (index: number) {
            dispatch(cloneTest(index))
        },
        deleteTest: function (index: number) {
            dispatch(deleteTest(index))
        },
        updateTestName: function (testIndex: number, name: string) {
            dispatch(updateTestName(testIndex, name))
        },
        updateInterimResults: function (testIndex: number, results: string[]) {
            dispatch(updateInterimResults(testIndex, results))
        },
        updateSkipFlag: function (testIndex: number, value: boolean) {
            dispatch(updateSkipFlag(testIndex, value))
        },
        updateOnlyFlag: function (testIndex: number, value: boolean) {
            dispatch(updateOnlyFlag(testIndex, value))
        },
        addInteraction: function (testIndex: number, interactionIndex?: number) {
            dispatch(addInteraction(testIndex, interactionIndex))
        },
        removeInteraction: function (testIndex: number, interactionIndex: number) {
            dispatch(removeInteraction(testIndex, interactionIndex))
        },
        updateInteractionInput: function (testIndex: number, interactionIndex: number, input: string) {
            dispatch(updateInteractionInput(testIndex, interactionIndex, input))
        },
        addAssertion: function (testIndex: number, interactionIndex: number) {
            dispatch(addAssertion(testIndex, interactionIndex))
        },
        deleteAssertion: function (testIndex: number, interactionIndex: number, itemIndex: number) {
            dispatch(deleteAssertion(testIndex, interactionIndex, itemIndex))
        },
        updateAssertionAction: function (testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
            dispatch(updateAssertionAction(testIndex, interactionIndex, itemIndex, value))
        },
        updateAssertionOperator: function (testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
            dispatch(updateAssertionOperator(testIndex, interactionIndex, itemIndex, value))
        },
        updateAssertionValue: function (testIndex: number, interactionIndex: number, itemIndex: number, value: string) {
            dispatch(updateAssertionValue(testIndex, interactionIndex, itemIndex, value))
        },
        resultsInit: function (yamlObject: any, testIndex: number | number[], status: string) {
            return dispatch(resultsInit(yamlObject, testIndex, status))
        },
        resultsPending: function (yamlObject: any) {
            return dispatch(resultsPending(yamlObject))
        },
        resultsUpdateInteractionAllStatus: function (testIndex: number, interactionIndex: number, status: string) {
            dispatch(resultsUpdateInteractionAllStatus(testIndex, interactionIndex, status))
        },
        resultsUpdate: function (testIndex: number, interactionIndex: number, conversationId: string, path: string, value: any) {
            dispatch(resultsUpdate(testIndex, interactionIndex, conversationId, path, value))
        },
        validateYamlSyntax: function (yaml: string) {
            dispatch(validateYamlSyntax(yaml))
        },
        resultsUpdateAllPendingStatus: function (testIndex: number, status: string) {
            dispatch(resultsUpdateAllPendingStatus(testIndex, status))
        },
        setLoading: (value: boolean) => {
            return dispatch(setLoading(value));
        },
        updateMonitoringSchedule: (cron: string, emailsToNotify: string, numbersToNotify: string): any => {
            return dispatch(updateMonitoringSchedule(cron, emailsToNotify, numbersToNotify));
        },
        updateTestSuiteDescription: (description: string) => dispatch(updateTestSuiteDescription(description)),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
    }
}

interface SourceState {
    showMessage: boolean;
    sourceName: string;
    selectedTestIndex: number;
    runningTestIndexes: number[];
    showSettings: boolean;
    originalName: string;
    snackbarActive: boolean;
    snackbarLabel: string;
    conversationId: string;

    monitoringModalIsVisible?: boolean;
    monitoringIsChecked?: boolean;
    monitoringScheduleType?: string;
    monitoringEditMode?: boolean;
    canSetupMonitoring?: boolean;
    canEditSchedule?: boolean;

    currentCron: string;
    currentCronExplained: string;
    currentEmails: string;
    currentNumbers: string;
}

export class SourceComponent extends React.Component<SourceProps & OwnProps, SourceState> {
    socketClient: any;
    streamClient: any;
    constructor(props: SourceProps & OwnProps) {
        super(props);
        this.state = {
            showMessage: true,
            sourceName: '',
            selectedTestIndex: 0,
            runningTestIndexes: [],
            showSettings: false,
            originalName: '',
            snackbarActive: false,
            snackbarLabel: '',
            conversationId: '',
            monitoringIsChecked: false,
            monitoringModalIsVisible: false,
            monitoringEditMode: false,
            currentCron: '',
            currentCronExplained: '',
            currentEmails: '',
            currentNumbers: '',
        };
    }

    onUnload = (e: any) => {
        e.preventDefault();
        const confirmationMessage = 'Some message';
        e.returnValue = confirmationMessage;
        return confirmationMessage
    }

    componentDidMount() {
        this.props.setCurrentPageProps({ title: "", subTitle: "" })

        // console.log('componentDidMount SourceComponent')
        // window.addEventListener("beforeunload", this.onUnload);
        this.loadSource();
    }

    componentWillUnmount() {
        if (this.socketClient) {
            this.socketClient.close();
            this.socketClient = undefined;
        }
        if (this.streamClient) {
            this.streamClient.close();
            this.streamClient = undefined;
        }
    }

    componentDidUpdate(prevProps: SourceProps & OwnProps) {
        // console.log('componentDidUpdate')
        if (prevProps.sourceId !== this.props.sourceId) {
            // console.log('componentDidUpdate loadSource')
            this.loadSource();
        }
    }

    loadSource = () => {
        // console.log('loadSource')
        this.props.setLoading(true);
        this.props.getSource(this.props.userId, this.props.sourceId)
            .then((result: any) => {
                const monitoringScheduleType = result?.monitoringConfig?.cron;
                const currentCron = result?.monitoringConfig?.cron;
                const currentCronExplained = result?.monitoringConfig?.cronExplained;
                const currentEmails = result?.monitoringConfig?.emailsToNotify;
                const currentNumbers = result?.monitoringConfig?.numbersToNotify;
                const monitoringIsChecked = !isEmpty(monitoringScheduleType)
                const { yamlObject } = this.props.source;
                this.props.resultsInit(yamlObject, this.state.selectedTestIndex, TestResultStatus.INITIAL);
                this.setState(prevState => ({
                    ...prevState,
                    originalName: result?.meta?.name,
                    monitoringScheduleType,
                    monitoringIsChecked,
                    currentCron,
                    currentCronExplained,
                    currentEmails,
                    currentNumbers
                }));

                // Validate YAML when first entering the page in case there are errors
                if (this.props.source.meta.isYamlEditor) {
                    this.onValidateYamlSyntax()
                }
                this.props.setLoading(false)
            })

    }

    handleChangeSelectedTestIndex = (selectedTestIndex: number) => {
        const { runningTestIndexes } = this.state;
        const isAnyTestRunning = runningTestIndexes.length > 0;

        if (isAnyTestRunning) {
            // Allow selecting completed tests or the currently running test
            const currentlyRunningTestIndex = runningTestIndexes[0];
            if (this.isTestCompleted(selectedTestIndex) || selectedTestIndex === currentlyRunningTestIndex) {
                this.setState({ selectedTestIndex });
            }
        } else {
            // Allow switching to any test when not running tests
            this.setState({ selectedTestIndex });
        }
    };

    isTestCompleted = (testIndex: number) => {
        const testResult = this.props.testResults[testIndex];
        return testResult?.interactions?.every((interaction: any) => {
            return interaction?.items?.every((item: any) =>
                item.status === TestResultStatus.COMPLETED)
        });
    };

    handleSourceNameChange = async (value: any) => {
        const prevName = get(this.props.source, 'name');
        const name = value.sourceName;
        if (!name) return;
        if (prevName === name) return;

        this.props.updateName(name)
            .then(() => {
                const projectId = this.props.source.config.projectId;
                const projectName = value.sourceName;
                const customerId = this.props.userId;
                updateReportResultsProjectName({ projectId, projectName, customerId });
            })
    }

    handleYamlChange = async (value: any) => {
        this.props.updateYaml(value)
    }

    handleShowSettings = () => {
        this.setState(prevState => ({
            ...prevState,
            showSettings: !prevState.showSettings,
        }));
    }

    handleSave = () => {
        this.props.setLoading(true);
        const { sourceId, userId, source } = this.props;
        const { yamlObject, config, meta, yaml, monitoringConfig, name } = source;
        const isYamlEditor = get(this.props.source, 'meta.isYamlEditor', false)

        const newMonitoringConfig = monitoringConfig?.cron === '??m' ? undefined : monitoringConfig;

        const yamlUpdated = isYamlEditor
            ? yaml
            : YamlHelper.toYaml(yamlObject);
        return this.props.saveSource(name, sourceId, yamlUpdated, config, meta, newMonitoringConfig)
            .then(() => this.props.setLoading(false))
            .catch(err => {
                this.setState({ snackbarLabel: 'There was an error while saving your test. Please review and try again.', snackbarActive: true })
                console.error(err)
                //Sentry.captureException(err);
                this.props.setLoading(false)
            });
    }

    handleRun = async () => {
        if (!this.validate()) return;
        // Clear any buffer on the stream player
        StreamPlayer.instance().reset()

        // Clear interim results
        this.props.updateInterimResults(this.state.selectedTestIndex, []);

        const lastRunDate = new Date().toISOString();
        globalWindow && Intercom.updateIntercom(globalWindow, {
            lastRunDate,
            company: {
                id: this.props.organizationId,
                lastRunDate,
            }
        });

        if (this.props.hasUnsavedChanges) {
            await this.handleSave();
        }

        const { yamlObject, config } = this.props.source;
        const { name: dashboardVoiceAppName } = this.props.source?.meta

        this.props.resultsInit(yamlObject, this.state.selectedTestIndex, TestResultStatus.PENDING);

        const yaml = YamlHelper.toYaml({
            tests: [{
                ...yamlObject.tests[this.state.selectedTestIndex],
                // remove skip and only flags when running by index
                skip: false,
                only: false,
            }]
        });

        this.setState({
            runningTestIndexes: [this.state.selectedTestIndex]
        });
        this.runScript(yaml, config, 'single', { meta: this.props.source.meta, dashboardVoiceAppName });
    }

    handleRunAll = async () => {
        if (!this.validate()) return;
        const lastRunDate = new Date().toISOString();
        globalWindow && Intercom.updateIntercom(globalWindow, {
            lastRunDate,
            company: {
                id: this.props.organizationId,
                lastRunDate,
            }
        });
        if (this.props.hasUnsavedChanges) {
            await this.handleSave();
        }
        const { yamlObject, config } = this.props.source;
        const { name: dashboardVoiceAppName } = this.props.source?.meta

        const onlyIndexes = yamlObject?.tests.reduce((acc: any, test: any, index: number) => {
            if (test.only) {
                acc.push(index);
            }
            return acc;
        }, []);
        const notSkipIndexes = yamlObject?.tests.reduce((acc: any, test: any, index: number) => {
            if (!test.skip) {
                acc.push(index);
            }
            return acc;
        }, []);
        const indexesToRun = onlyIndexes.length > 0 ? onlyIndexes : notSkipIndexes;

        indexesToRun.forEach((index: number) => {
            this.props.updateInterimResults(index, []);
        });

        this.setState({
            runningTestIndexes: indexesToRun,
            selectedTestIndex: indexesToRun[0]  // Automatically select the first test to run
        });

        this.props.resultsInit(yamlObject, indexesToRun, TestResultStatus.PENDING);

        const yaml = YamlHelper.toYaml(yamlObject);

        this.runScript(yaml, config, 'all', { meta: this.props.source.meta, dashboardVoiceAppName });

    }

    handleCancel = async () => {
        if (!this.socketClient) return;
        if (!this.state.conversationId) return;
        const platform = this.props.source?.config?.platform || "alexa";

        const payload = {
            action: 'stop',
            conversationId: this.state.conversationId,
            platform
        }
        this.socketClient.send(payload)
        const { yamlObject } = this.props.source;
        const { runningTestIndexes, selectedTestIndex } = this.state;
        const isRunningMultipleTests = runningTestIndexes.length > 0;

        //this.props.updateInterimResults(this.state.selectedTestIndex, []);
        if (this.state.runningTestIndexes.length > 0) {
            this.state.runningTestIndexes.forEach(index => {
                this.props.updateInterimResults(index, []);
            });
        }

        if (isRunningMultipleTests) {
            const indexes = yamlObject?.tests.map((item: any, index: number) => {
                return index;
            });
            this.props.resultsInit(yamlObject, indexes, TestResultStatus.INITIAL);
        } else {
            this.props.resultsUpdateAllPendingStatus(selectedTestIndex, TestResultStatus.INITIAL)
        }



        this.postTestCleanup(undefined)
    }

    runScript = async (yaml: string, config: any, runningMode: 'single' | 'all', context = {}) => {
        this.props.setLoading(true);
        this.setState(prevState => ({
            ...prevState,
            conversationId: '',
        }));

        // Clear interim results before starting a new test
        if (runningMode === 'single') {
            this.props.updateInterimResults(this.state.selectedTestIndex, []);
        } else {
            // For 'all' mode, clear results for all running tests
            this.state.runningTestIndexes.forEach(index => {
                this.props.updateInterimResults(index, []);
            });
        }

        this.socketClient = new WebsocketClient().init(BESPOKEN_API_WS_URL, {
            onOpen: () => {
                this.socketClient.send({
                    action: 'runSuite',
                    yaml,
                    config: { ...config, client: 'dashboard' },
                    context: { ...context, userId: this.props.userId },
                });
            },
            onClose: () => {
                this.postTestCleanup(undefined)
            },
            onMessage: (message: any) => {
                try {
                    const payload = JSON.parse(message);
                    const { runningTestIndexes, selectedTestIndex } = this.state;
                    const isAnyTestRunning = runningTestIndexes.length > 0;

                    if (payload?.type === "result") {
                        const interactionResult = payload.body;
                        const { conversationID } = payload.body || {}

                        let hasError = false
                        for (let index = 0; index < interactionResult?.assertions?.length; index++) {
                            const assertion = interactionResult.assertions[index];
                            // Use the correct test index based on the running mode
                            const testIndex = runningMode === 'single' ? selectedTestIndex : interactionResult.testIndex;
                            const actual = interactionResult?.result?.error
                                ? interactionResult.result.error.message
                                : assertion?.actual
                            const error = interactionResult?.result?.error?.message
                            hasError = error !== undefined
                            this.props.resultsUpdate(testIndex, interactionResult.relativeIndex, conversationID, assertion?.path, {
                                actual: actual,
                                error: error,
                                path: assertion?.path,
                                passed: assertion?.passed,
                                status: TestResultStatus.COMPLETED,
                            });
                        }

                        if (hasError) {
                            this.postTestCleanup(runningMode === 'single' ? selectedTestIndex : interactionResult.testIndex)
                        }

                        // Check if this is the last assertion of the test
                        if (isAnyTestRunning) {
                            const currentTest = this.props.source.yamlObject.tests[runningMode === 'single' ? selectedTestIndex : interactionResult.testIndex];
                            const lastInteractionIndex = currentTest.interactions.length - 1;

                            if (interactionResult.relativeIndex === lastInteractionIndex) {
                                // This is the last assertion, so the test is complete
                                this.setState(prevState => ({
                                    runningTestIndexes: prevState.runningTestIndexes.filter(index => index !== (runningMode === 'single' ? selectedTestIndex : interactionResult.testIndex))
                                }));

                                // Clear the interim results for the completed test
                                this.props.updateInterimResults(interactionResult.testIndex, [])
                            }
                        }
                    } else if (payload?.type === "onTestSuiteEnd") {
                        this.postTestCleanup(runningMode === 'single' ? selectedTestIndex : runningTestIndexes[0])
                    } else if (payload?.type === "onInterimResults") {
                        const testIndex = runningMode === 'single' ? this.state.selectedTestIndex : this.state.runningTestIndexes[0];
                        this.props.updateInterimResults(testIndex, payload.interimResults);
                    } else if (payload?.type === "conversationId") {
                        this.props.setLoading(false);

                        // Start streaming audio from the server if this is a phone call
                        if (!this.streamClient && this.props.source?.config?.platform === 'phone') {
                            this.openMediaSocket(payload.mediaURL, payload.conversationId)
                        }
                        this.setState({
                            conversationId: payload.conversationId,
                        });
                    } else if (payload?.type === "pong") {
                    }
                    else {
                    }
                } catch (error) {
                }
            }
        });

    }

    openMediaSocket(mediaURL: string, conversationID: string) {
        const playback = StreamPlayer.instance().play()
        this.streamClient = new WebsocketClient().init(mediaURL + '/' + conversationID, {
            onMessage: (message: any) => {
                //console.info("received streaming data from server: " + message.length)
                playback.pushBlob(message)
            },
            onOpen: () => {
                //console.info("socket opened")
            }
        });
    }

    postTestCleanup(index: number) {
        if (index !== undefined) {
            this.props.resultsUpdateAllPendingStatus(index, TestResultStatus.COMPLETED)
            this.props.updateInterimResults(index, []);
        }

        // If we're running multiple tests, clear results for all running tests
        if (this.state.runningTestIndexes.length > 1) {
            this.state.runningTestIndexes.forEach(testIndex => {
                this.props.updateInterimResults(testIndex, []);
            });
        }

        if (this.socketClient) {
            this.socketClient.close();
            this.socketClient = undefined
        }

        if (this.streamClient) {
            this.streamClient.close();
            this.streamClient = undefined
        }

        this.props.setLoading(false);
        this.setState(prevState => ({
            ...prevState,
            runningTestIndexes: [] as number[],
            conversationId: '',
        }));
    }

    validate = () => {
        const platform = this.props.source?.config?.platform || "alexa";
        const phoneNumber = this.props.source?.config?.phoneNumber;
        const selectedIndex = this.state.selectedTestIndex;
        const interactions = this.props.source?.yamlObject?.tests.length
            && this.props.source?.yamlObject?.tests[selectedIndex]
            && this.props.source?.yamlObject?.tests[selectedIndex]?.interactions;

        if (["phone", "twilio", "sms", "whatsapp"].indexOf(platform) > -1) {
            if (!phoneNumber) {
                this.handleShowSnackbar("The phone number is required.");
                return false;
            } else {
                const numberRegex = new RegExp(/^\+?[1-9]\d{1,14}$/);
                const isValidNumber = numberRegex.test(phoneNumber);
                if (!isValidNumber) {
                    this.handleShowSnackbar("The phone number is invalid. Please enter a valid US number or follow the E.164 format.");
                    return false;
                }
            }
        }

        for (const interaction of interactions) {
            if (!interaction.input) {
                this.handleShowSnackbar(`Remove empty input`);
                return false;
            }
            for (const item of interaction.items) {
                if (!item.action) {
                    this.handleShowSnackbar(`Remove empty assertion at interaction: ${interaction.input}`);
                    return false;
                }
                if (!item.value) {
                    this.handleShowSnackbar(`Remove empty assertion at interaction: ${interaction.input}`);
                    return false;
                }
            }
        }
        return true;
    }

    handleShowSnackbar = (label?: any) => {
        this.setState(prevState => ({
            ...prevState,
            snackbarLabel: label,
            snackbarActive: true
        }));
    }

    handleSnackbarClick = () => {
        this.setState({ ...this.state, snackbarActive: false });
    }

    handleYamlEditorChange = async () => {
        const isYamlEditor = get(this.props.source, 'meta.isYamlEditor', false);
        let { yamlObject, yaml } = this.props.source;
        await this.props.updateMeta('isYamlEditor', !isYamlEditor)
        if (!isYamlEditor) {
            yaml = YamlHelper.toYaml(yamlObject);
            await this.props.updateYaml(yaml);
        } else {
            const response: any = await BespokenApi.toYamlObject(yaml)
            await this.props.updateYamlObject(response);
        }

        // if (this.props.hasUnsavedChanges) {
        //     this.handleSave();
        // }
    }

    onValidateYamlSyntax = () => {
        const { yaml } = this.props.source;
        this.props.validateYamlSyntax(yaml);
    }

    downloadSource = async () => {
        const { sourceId } = this.props;
        try {
            const blobResponse = await fetchInternalApi(({ userId, organizationId }) =>
                `/users/${userId}/organizations/${organizationId}/testsuites/${sourceId}/download`, 'GET')
            const downloadUrl = window.URL.createObjectURL(blobResponse);
            const link = document.createElement('a');
            const defaultFilename = `${this.props?.source?.name}.e2e.zip`;
            link.href = downloadUrl;
            link.setAttribute('download', defaultFilename);
            link.setAttribute('style', 'display: none;');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error(err)
        }
    }

    render() {
        let isYamlEditor = get(this.props.source, 'meta.isYamlEditor', false)

        const virtualDevices = this.props?.virtualDevices || [];
        return (
            <form className={validationStyle.fix_mdl} style={{ position: "relative", top: "-60px", zIndex: 200 }}>
                <HasPrivilege onPrivilegesLoaded={privileges => {
                    this.setState({
                        canSetupMonitoring: includes(privileges, "can-use-monitoring"),
                        canEditSchedule: includes(privileges, "can-edit-schedule"),
                    })
                }} />
                <Cell col={12} tablet={12}>
                    <div className={sourceStyle.page_header_section}>
                        <div className={sourceStyle.test_suite_icon}>
                            <div>
                                <PlatformIcon platform={this.props.source?.config?.platform} />
                            </div>
                        </div>
                        <div className={sourceStyle.test_suite_title} data-intercom-target="SuiteTitle">
                            <div className={sourceStyle.title_input}>
                                <InlineEditInput className={inputTheme.validation_page}
                                    classEditing={inputTheme.validation_page_edit}
                                    propName={"sourceName"}
                                    value={this.props.source?.name || this.props.source?.meta?.name || ''}
                                    editProps={{ maxLength: 50 }}
                                    change={this.handleSourceNameChange}
                                />
                            </div>
                            <div className={validationStyle.test_suite_description}>
                                <InplaceEditor
                                    lines={1}
                                    saveOnBlur={true}
                                    maxLength={200}
                                    truncateLength={80}
                                    placeHolder={"Enter a test suite description..."}
                                    value={this.props?.source?.meta?.testSuiteDescription}
                                    onChanged={suiteDescription => this.props?.updateTestSuiteDescription(suiteDescription)}
                                    tooltip={this.props?.source?.meta?.testSuiteDescription}
                                />
                            </div>
                        </div>
                        <div className={sourceStyle.test_suite_attributes}>
                            <SourceHeader
                                source={this.props.source}
                                virtualDevices={virtualDevices}
                                updateConfig={this.props.updateConfig}
                                saveSource={this.handleSave}
                                hasUnsavedChanges={this.props.hasUnsavedChanges}
                                showSnackbarMessage={this.handleShowSnackbar}
                            />
                        </div>
                        <div className={sourceStyle.test_suite_actions}>
                            <SwitchLabel
                                label="YAML Editor"
                                tooltipText="The YAML Editor is a more powerful, advanced way of creating end to end tests. Learn more at https://read.bespoken.io"
                                checked={isYamlEditor}
                                onChange={this.handleYamlEditorChange}
                                size="small"
                            />
                            <SwitchLabel
                                label="Monitoring"
                                tooltipText={this.state?.canSetupMonitoring ?
                                    "Enable Monitoring and get notified instantly when there is a change in your tests results over time." :
                                    "Enable monitoring and get notified instantly when there is a change in your tests results over time. Available for Professional and Enterprise plans."
                                }
                                checkedTooltipText={this.getSelectedScheduleTypeLabel()}
                                disabled={!this.state?.canSetupMonitoring}
                                checked={this.state.monitoringIsChecked}
                                onChange={(selected: any) => this.onSwitchChange(selected)}
                                size="small"
                            />
                            <IconLabelButton
                                icon={<BespokenDownloadIcon />}
                                label="Download"
                                size="medium"
                                gap="12px"
                                onClick={this.downloadSource}
                            />
                            <IconLabelButton
                                icon={<BespokenGearIcon />}
                                label="Advanced"
                                size="medium"
                                gap="12px"
                                onClick={this.handleShowSettings}
                            />
                        </div>
                    </div>

                </Cell>
                <Cell col={12} tablet={12} style={{ height: '20px', display: "grid", alignItems: "center" }}>
                    <Line />
                </Cell>
                {isYamlEditor ? (
                    <Cell className={validationStyle.main_container} col={12} >
                        <TestResults
                            source={this.props.source}
                            testResults={this.props.testResults}
                            validateYamlSyntax={this.onValidateYamlSyntax}
                            syntaxError={this.props.syntaxError}
                        />
                        <YamlEditor
                            source={this.props.source}
                            handleYamlChange={this.handleYamlChange}
                        />
                    </Cell>
                ) : (
                    <Cell className={validationStyle.main_container} col={12} data-id="validation-interactions-script">
                        <TestList
                            source={this.props.source}
                            selectedTestIndex={this.state.selectedTestIndex}
                            runningTestIndexes={this.state.runningTestIndexes}
                            updateTestName={this.props.updateTestName}
                            updateOnlyFlag={this.props.updateOnlyFlag}
                            updateSkipFlag={this.props.updateSkipFlag}
                            addTest={this.props.addTest}
                            deleteTest={this.props.deleteTest}
                            cloneTest={this.props.cloneTest}
                            handleChangeSelectedTestIndex={this.handleChangeSelectedTestIndex}
                            reorderTest={this.props.reorderTest}
                            testResults={this.props.testResults}
                            handleRunAll={this.handleRunAll}
                        />
                        <TestEditor
                            source={this.props.source}
                            interimResults={this.props.interimResults}
                            testResults={this.props.testResults}
                            runningTestIndexes={this.state.runningTestIndexes}
                            addInteraction={this.props.addInteraction}
                            removeInteraction={this.props.removeInteraction}
                            updateInteractionInput={this.props.updateInteractionInput}
                            addAssertion={this.props.addAssertion}
                            deleteAssertion={this.props.deleteAssertion}
                            updateAssertionAction={this.props.updateAssertionAction}
                            updateAssertionOperator={this.props.updateAssertionOperator}
                            updateAssertionValue={this.props.updateAssertionValue}
                            selectedTestIndex={this.state.selectedTestIndex}
                        >
                            <div className={sourceStyle.new_visual_editor}>
                                {this.props.hasUnsavedChanges &&
                                    <span className={sourceStyle.warning_message}>You have unsaved changes</span>
                                }

                                <Button
                                    icon={<BespokenSaveIcon />}
                                    data-id="skill-save-button"
                                    id={"visual_save_button"}
                                    theme={bespokenButton}
                                    disabled={this.state.runningTestIndexes.length > 0}
                                    type="button"
                                    onClick={this.handleSave}
                                    primary={true}>
                                    <span>Save</span>
                                </Button>
                                <Button
                                    icon={this.state.runningTestIndexes.length > 0 ? <SpinnerImg data-id={"img-status"} /> : <BespokenPowerIcon />}
                                    data-id="skill-run-button"
                                    theme={bespokenButton}
                                    accent={true}
                                    disabled={this.state.runningTestIndexes.length > 0 && !this.state.conversationId}
                                    className={`${bespokenButton.large} ${this.state.runningTestIndexes.length > 0 ? sourceStyle.cancel : ""}`}
                                    onClick={this.state.runningTestIndexes.length > 0 ? this.handleCancel : isYamlEditor ? this.handleRunAll : this.handleRun}
                                    data-intercom-target="RunTest"
                                >
                                    {this.state.runningTestIndexes.length > 0 ?
                                        (
                                            <span>Cancel</span>
                                        ) :
                                        (
                                            <span>Run</span>
                                        )
                                    }
                                </Button>
                            </div>
                        </TestEditor>

                    </Cell>
                )}
                {isYamlEditor &&
                    <div className={sourceStyle.new_visual_editor}>
                        {this.props.hasUnsavedChanges &&
                            <span className={sourceStyle.warning_message}>You have unsaved changes</span>
                        }

                        <Button
                            icon={<BespokenSaveIcon />}
                            data-id="skill-save-button"
                            id={"visual_save_button"}
                            theme={bespokenButton}
                            disabled={this.state.runningTestIndexes.length > 0}
                            type="button"
                            onClick={this.handleSave}
                            primary={true}>
                            <span>Save</span>
                        </Button>
                        <Button
                            icon={this.state.runningTestIndexes.length > 0 ? <SpinnerImg data-id={"img-status"} /> : <BespokenPowerIcon />}
                            data-id="skill-run-button"
                            theme={bespokenButton}
                            accent={true}
                            disabled={this.state.runningTestIndexes.length > 0 && !this.state.conversationId}
                            className={`${bespokenButton.large} ${this.state.runningTestIndexes.length > 0 ? sourceStyle.cancel : ""}`}
                            onClick={this.state.runningTestIndexes.length > 0 ? this.handleCancel : isYamlEditor ? this.handleRunAll : this.handleRun}
                        >
                            {this.state.runningTestIndexes.length > 0 ?
                                (
                                    <span>Cancel</span>
                                ) :
                                (
                                    <span>Run</span>
                                )
                            }
                        </Button>
                    </div>
                }
                <SourceSettings
                    source={this.props.source}
                    showSettings={this.state.showSettings}
                    handleShowSettings={this.handleShowSettings}
                    updateConfig={this.props.updateConfig}
                    updateConfigNested={this.props.updateConfigNested}
                    getConfig={() => this.props.source.config}
                    saveSource={this.handleSave}
                    hasUnsavedChanges={this.props.hasUnsavedChanges}
                />
                <Snackbar
                    className="sm-snackbar"
                    action="Dismiss"
                    type="cancel"
                    active={this.state.snackbarActive}
                    label={this.state.snackbarLabel}
                    timeout={10000}
                    onClick={this.handleSnackbarClick}
                    onTimeout={this.handleSnackbarClick}
                />
                <MonitoringModal
                    canEditSchedule={this.state.canEditSchedule}
                    showModal={this.state.monitoringModalIsVisible}
                    onCancel={async () => this.handleCancelMonitoring()}
                    onSuccess={async (cron, cronExplained, emailsToNotify, numbersToNotify) => this.handleSaveMonitoring(cron, cronExplained, emailsToNotify, numbersToNotify)}
                    onDelete={async () => this.handleDisableMonitoring()}
                    selectedScheduleType={this.state.monitoringScheduleType}
                    isEditMode={this.state.monitoringEditMode}
                    currentCron={this.state.currentCron}
                    currentEmails={this.state.currentEmails}
                    currentPhoneNumbers={this.state.currentNumbers}
                />
            </form>
        );
    }

    getSelectedScheduleTypeLabel(): string {
        const cronExplained = this.state.currentCronExplained || 'Not set';
        const emailsToNotify = this.state.currentEmails || 'Not set';
        const numbersToNotify = this.state.currentNumbers;
        let message = `Schedule: ${cronExplained}. Emails to notify: ${emailsToNotify}.`;
        if (numbersToNotify) {
            message = `Schedule: ${cronExplained}. Emails to notify: ${emailsToNotify}. Numbers to notify: ${numbersToNotify}.`;
        }
        return message;
    }

    async handleCancelMonitoring(): Promise<void> {
        const monitoringIsChecked = this.state.monitoringEditMode ? true : false;
        const monitoringModalIsVisible = false;

        const currentCron = this.state.monitoringEditMode ? this.state.currentCron : '';
        const currentCronExplained = this.state.monitoringEditMode ? this.state.currentCronExplained : '';
        const currentEmails = this.state.monitoringEditMode ? this.state.currentEmails : '';
        const currentNumbers = this.state.monitoringEditMode ? this.state.currentNumbers : '';

        this.setState(prevState => ({
            ...prevState,
            monitoringModalIsVisible,
            currentCron,
            currentCronExplained,
            currentEmails,
            currentNumbers,
            monitoringIsChecked
        }));
    }

    async handleSaveMonitoring(cron: string, cronExplained: string, emailsToNotify: string, numbersToNotify: string): Promise<void> {
        const monitoringModalIsVisible = false;
        const monitoringIsChecked = true;

        this.setState(prevState => ({
            ...prevState,
            currentCron: cron,
            currentCronExplained: cronExplained,
            currentEmails: emailsToNotify,
            currentNumbers: numbersToNotify,
            monitoringIsChecked,
            monitoringModalIsVisible,
        }));

        await this.props.updateMonitoringSchedule(cron, emailsToNotify, numbersToNotify);

        if (this.props.hasUnsavedChanges) {
            await this.handleSave();
        }
    }

    onSwitchChange(monitoringIsChecked: any) {
        if (this.state.monitoringIsChecked) {
            // Toggle is already on, enter edit mode
            this.setState(prevState => ({
                ...prevState,
                monitoringEditMode: true,
                monitoringModalIsVisible: true
            }));
        } else {
            // Turning on monitoring for the first time
            const monitoringModalIsVisible = true;
            this.setState(prevState => ({
                ...prevState,
                monitoringIsChecked,
                monitoringModalIsVisible,
                monitoringEditMode: false
            }));
        }
    }

    handleDisableMonitoring() {
        const monitoringIsChecked = false;
        const monitoringModalIsVisible = false;
        const monitoringScheduleType: any = ''
        const currentCron = ''
        const currentCronExplained = ''
        const currentEmails = ''
        const currentNumbers = ''

        this.props.updateMonitoringSchedule(monitoringScheduleType, undefined, undefined)
            .then(() => {
                if (this.props.hasUnsavedChanges) {
                    this.handleSave();
                }
            });

        this.setState(prevState => ({
            ...prevState,
            monitoringIsChecked, monitoringModalIsVisible,
            monitoringScheduleType, currentCron, currentCronExplained, currentEmails, currentNumbers
        }));
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SourceComponent);
